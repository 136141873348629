import ExportMenu from "@/components/collections/ExportMenu";
import Archie from "@/components/search/Archie";
import SearchResults from "@/components/search/SearchResults";

import {
  AugmentedSearchResponse,
  ClassicSearchResponse,
} from "@/lib/SemanticScholarApi";
import Container from "@/ui/Container";
import Pagination from "@/ui/Pagination";
import SearchArticle from "@/ui/SearchArticle";
import SearchLoading from "@/ui/pending/SearchLoading";

import { Box, Icon, IconButton, Text } from "@chakra-ui/react";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoArrowUp } from "react-icons/io5";

import {
  Await,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";

const Search = () => {
  const { results, page, query } = useLoaderData() as {
    results: Promise<ClassicSearchResponse | AugmentedSearchResponse>;
    page: number;
    query: string | undefined;
  };
  const navigate = useNavigate();
  const navigation = useNavigation();
  const busy =
    navigation.state === "loading" &&
    navigation.location.pathname === "/search";

  const [shouldDisplayScrollToTop, setShouldDisplayScrollToTop] =
    useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, query, busy]);

  useEffect(() => {
    const displayScrollToTop = () => {
      if (window.scrollY > 100) {
        setShouldDisplayScrollToTop(true);
      } else {
        setShouldDisplayScrollToTop(false);
      }
    };

    window.addEventListener("scroll", displayScrollToTop, { passive: true });

    return () => window.removeEventListener("scroll", displayScrollToTop);
  }, []);

  const { t } = useTranslation();

  return (
    <Container hasSearch>
      <Box
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={4}
        w={"100%"}
        h={"100%"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
      >
        <>
          {busy ? (
            <SearchLoading />
          ) : (
            <>
              <Suspense fallback={<SearchLoading />}>
                <Await
                  resolve={results}
                  errorElement={<Text>{t("search.error")}</Text>}
                >
                  {(
                    results: ClassicSearchResponse | AugmentedSearchResponse
                  ) => {
                    if (results.isAiSearch) {
                      return (
                        <>
                          <Archie data={results} />
                          <SearchResults />
                        </>
                      );
                    }

                    return (
                      <>
                        <Box
                          gap={8}
                          p={2}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Box alignSelf={"flex-end"}>
                            <ExportMenu
                              papers={results.data}
                              title={query as string}
                            />
                          </Box>
                          {results.data.map((document) => (
                            <SearchArticle
                              key={document.paperId}
                              {...document}
                            />
                          ))}
                        </Box>
                        <Pagination
                          total={results.total > 1000 ? 1000 : results.total}
                          current={page}
                          onChange={(currentPage) => {
                            const queryParams = new URLSearchParams(
                              window.location.search
                            );

                            queryParams.set("page", currentPage.toString());
                            navigate({
                              search: queryParams.toString(),
                            });
                          }}
                          resultsPerPage={10}
                        />
                      </>
                    );
                  }}
                </Await>
              </Suspense>
            </>
          )}
        </>
      </Box>
      <Suspense fallback={null}>
        <Await resolve={results} errorElement={null}>
          <IconButton
            icon={<Icon as={IoArrowUp} w={6} h={6} />}
            aria-label="Scroll to top"
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
            display={shouldDisplayScrollToTop ? "block" : "none"}
            position={"fixed"}
            bottom={8}
            right={8}
          />
        </Await>
      </Suspense>
    </Container>
  );
};

export default Search;
