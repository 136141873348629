import { Collection } from "@/lib/types/Collection";
import {
  Button,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";
import { updateDoc } from "firebase/firestore";
import { CopyIcon } from "@chakra-ui/icons";

type ShareCollectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  collection: Collection;
};

const baseUrl = `${window.location.origin}/collections/invite/`;

const ShareCollectionModal = (props: ShareCollectionModalProps) => {
  const { isOpen, onClose, collection } = props;
  const { t } = useTranslation();

  const toast = useToast();
  useEffect(() => {
    if (isOpen && !collection.viewerCode && !collection.maintainerCode) {
      const viewerCode = nanoid(10);
      const maintainerCode = nanoid(10);

      updateDoc(collection.ref, {
        viewerCode,
        maintainerCode,
      });
    }
  }, [collection, isOpen]);

  const copyCode = async (code: string) => {
    await navigator.clipboard.writeText(`${baseUrl}${code}`);
    toast({
      title: t("collections.share.codeCopied"),
      status: "success",
    });
  };

  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("collections.share.title")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} alignItems={"stretch"}>
            <Text>{t("collections.share.description")}</Text>
            {collection.viewerCode ? (
              <VStack alignItems={"flex-start"}>
                <Text fontWeight={"bold"}>{t("collections.share.viewer")}</Text>

                <InputGroup>
                  <Input
                    value={`${baseUrl}${collection.viewerCode}`}
                    isReadOnly
                  />
                  <InputRightAddon>
                    <IconButton
                      aria-label={t("collections.share.copyViewer")}
                      onClick={() => copyCode(collection.viewerCode!)}
                      icon={<CopyIcon />}
                    />
                  </InputRightAddon>
                </InputGroup>
                <Text fontStyle={"italic"}>
                  {t("collections.share.viewerDescription")}
                </Text>
              </VStack>
            ) : (
              <Skeleton height="20px" />
            )}
            {collection.maintainerCode ? (
              <VStack alignItems={"flex-start"}>
                <Text fontWeight={"bold"}>
                  {t("collections.share.maintainer")}
                </Text>

                <InputGroup>
                  <Input
                    value={`${baseUrl}${collection.maintainerCode}`}
                    isReadOnly
                  />
                  <InputRightAddon>
                    <IconButton
                      aria-label={t("collections.share.copyMaintainer")}
                      onClick={() => copyCode(collection.maintainerCode!)}
                      icon={<CopyIcon />}
                    />
                  </InputRightAddon>
                </InputGroup>
                <Text fontStyle={"italic"}>
                  {t("collections.share.maintainerDescription")}
                </Text>
              </VStack>
            ) : (
              <Skeleton height="20px" />
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{t("collections.share.close")}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareCollectionModal;
