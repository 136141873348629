import { functions } from "@/lib/firebase";
import { httpsCallable } from "firebase/functions";

type SetSessionTokenRequest = {
  token: string;
};

type SetSessionTokenResponse = {
  success: boolean;
};

const setSessionToken = httpsCallable<
  SetSessionTokenRequest,
  SetSessionTokenResponse
>(functions, "setWebSessionToken");

export { setSessionToken };
