import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  CardFooter,
  useDisclosure,
  LinkBox,
  LinkOverlay,
  IconButton,
  Icon,
  HStack,
  Box,
  Tag,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import Tldr from "./Tldr";
import { PaperResponse, SearchResponseItem } from "@/lib/SemanticScholarApi";
import slugify from "slugify";

import useArchieData from "@/hooks/useArchieData";
import SearchArticleAnswer from "./SearchArticleAnswer";
import { useCollections } from "@/hooks/useCollections";
import AddElementToCollectionModal from "@/components/collections/AddElementToCollectionModal";
import {
  MdCollectionsBookmark,
  MdOutlineCollectionsBookmark,
} from "react-icons/md";
import { useTranslation } from "react-i18next";

const SearchArticle = (
  props: (SearchResponseItem | PaperResponse) & {
    hideArchieAnswer?: boolean;
    index?: number;
  }
) => {
  const {
    title,
    tldr,
    journal,
    authors,
    citationCount,
    publicationDate,
    year,
    corpusId,
    hideArchieAnswer = false,
    index,
    publicationTypes,
  } = props;

  const { displayed, archieYO } = useArchieData((state) => state);
  const { collections } = useCollections();
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();
  const slug = slugify(title, {
    lower: true,
    strict: true,
  });

  const { t } = useTranslation();

  return (
    <>
      <LinkBox
        as={Card}
        id={`paper-${corpusId}`}
        width={"100%"}
        bgColor={"#fff"}
        borderRadius={28}
        p={2}
        minH={200}
      >
        <CardHeader
          pb={0}
          display={"flex"}
          gap={4}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          w={"100%"}
        >
          <HStack spacing={4} alignItems={"center"}>
            {index && (
              <Box
                flexShrink={0}
                bgColor={"brand.500"}
                borderRadius={16}
                p={0}
                overflow={"hidden"}
                w={8}
                h={8}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Text color={"#fff"} m={0} fontWeight={"bold"}>
                  {index}
                </Text>
              </Box>
            )}
            <LinkOverlay as={Link} to={`/article/${slug}/${corpusId}`}>
              <Heading
                fontFamily={"Be Vietnam Pro"}
                fontWeight={900}
                as={"h2"}
                size={"md"}
              >
                {title}
              </Heading>
            </LinkOverlay>
          </HStack>
          {displayed.aiSearch &&
            displayed.insights &&
            archieYO &&
            !hideArchieAnswer && <SearchArticleAnswer corpusId={corpusId} />}
        </CardHeader>
        <CardBody
          justifyContent={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          {tldr && (
            <Text>
              <Tldr />
              {tldr.text}
            </Text>
          )}
          {publicationTypes && (
            <HStack mt={6} spacing={2}>
              {publicationTypes.map((type) => (
                <Tag key={type}>
                  {t(
                    `search.filters.publicationTypes.${type.replaceAll(
                      " ",
                      ""
                    )}`
                  )}
                </Tag>
              ))}
            </HStack>
          )}
        </CardBody>
        <CardFooter
          pt={0}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <Text fontSize={12} fontStyle={"italic"}>
            {publicationDate
              ? new Date(publicationDate).toLocaleDateString()
              : year}{" "}
            | {journal?.name} |{" "}
            {authors
              .slice(0, 1)
              .map((author) => author.name)
              .join(", ")}{" "}
            et al. | {citationCount} citations
          </Text>
          <IconButton
            onClick={openModal}
            variant={"ghost"}
            aria-label="Add to collection"
            icon={
              <Icon
                as={
                  collections.find((c) =>
                    c.papers.includes(corpusId.toString())
                  )
                    ? MdCollectionsBookmark
                    : MdOutlineCollectionsBookmark
                }
                w={6}
                h={6}
              />
            }
          />
        </CardFooter>
      </LinkBox>
      <AddElementToCollectionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        elementId={corpusId.toString()}
        elementType="paper"
      />
    </>
  );
};

export default SearchArticle;
