import useArchieData from "@/hooks/useArchieData";

import { Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";

import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, useSearchParams } from "react-router-dom";
import SearchFilters from "./search/SearchFilters";
import { IoClose, IoFilterOutline, IoHelpCircleOutline } from "react-icons/io5";
import useSearchSubmit from "@/hooks/useSearchSubmit";
import { useUserSession } from "@/hooks/useUserSession";

const SearchBar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const formRef = useRef<HTMLFormElement>(null);
  const { displayed, setArchieData } = useArchieData();
  const [params] = useSearchParams();
  const { user } = useUserSession();
  const [search, setSearch] = useState(params.get("q") || "");

  const handleSubmit = useSearchSubmit(formRef);
  const { t } = useTranslation();

  return (
    <Form
      style={{
        flexGrow: 1,
      }}
      ref={formRef}
      method="get"
      action="/search"
      id="search-form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <FormControl>
        <InputGroup
          bgColor={"#fff"}
          borderRadius={25}
          _focusWithin={{
            outline: "2px solid #00BCB0",
          }}
        >
          <InputLeftElement>
            <Search2Icon color={"gray.300"} />
          </InputLeftElement>
          <Input
            minH={10}
            maxH={100}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            as={Textarea}
            defaultValue={params.get("q") || ""}
            type="text"
            placeholder={t("search.placeholder")}
            name="q"
            id="q"
            border={"none"}
            borderRadius={25}
            required
            inputMode="search"
            onKeyUp={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                handleSubmit();

                setSearch((prev) => prev.trimEnd());
              }
            }}
            _focusVisible={{
              outline: "none",
            }}
            h={10}
            sx={{
              scrollbarWidth: "none",
            }}
          />

          <InputRightAddon
            bgColor={"#fff"}
            border={"none"}
            pl={0}
            borderRadius={25}
            p={1}
          >
            {" "}
            {search.length > 0 && (
              <IconButton
                aria-label="clear"
                icon={<Icon as={IoClose} w={6} h={6} />}
                onClick={() => setSearch("")}
                variant={"ghost"}
              />
            )}
            <Button h={8} variant={"primary"} type="submit">
              <Box hideBelow={"md"}>{t("search.submit")}</Box>
              <Icon as={Search2Icon} w={4} h={4} hideFrom={"md"} />
            </Button>
          </InputRightAddon>
        </InputGroup>
      </FormControl>
      <HStack justifyContent={"flex-start"} flexWrap={"wrap"}>
        <FormControl w={"auto"} display={"flex"} alignItems={"center"}>
          <Switch
            onChange={(e) => {
              if (e.target.checked) {
                setArchieData({
                  displayed: {
                    ...displayed,
                    aiSearch: true,
                  },
                });
              } else {
                setArchieData({
                  displayed: {
                    aiSearch: false,
                    insights: false,
                    summary: false,
                  },
                });
              }
            }}
            isChecked={displayed.aiSearch}
            mr={2}
            id="ai-search"
            name="ai-search"
            colorScheme="brand"
            value={"true"}
          />
          <FormLabel htmlFor="ai-search" mt={2}>
            {t("search.ai_search")}
          </FormLabel>
          <Popover>
            <PopoverTrigger>
              <IconButton
                ml={-4}
                p={0}
                aria-label="info"
                icon={
                  <Icon as={IoHelpCircleOutline} w={6} h={6} color={"gray"} />
                }
                variant={"ghost"}
                colorScheme={"brand"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Text>{t("search.ai_search_description")}</Text>
                {user?.currentPlan !== "premium" && (
                  <Text>
                    {t("search.pricing", {
                      price: 2,
                    })}
                  </Text>
                )}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormControl>
        <FormControl w={"auto"} display={"flex"} alignItems={"center"}>
          <Switch
            onChange={(e) => {
              setArchieData({
                displayed: {
                  ...displayed,
                  aiSearch: e.target.checked ? true : displayed.aiSearch,
                  insights: e.target.checked,
                },
              });
            }}
            isChecked={displayed.insights}
            mr={2}
            id="insights"
            name="insights"
            colorScheme="brand"
            value={"true"}
          />
          <FormLabel htmlFor="insights" mt={2}>
            Archie
          </FormLabel>

          <Popover>
            <PopoverTrigger>
              <IconButton
                ml={-4}
                p={0}
                aria-label="info"
                icon={
                  <Icon as={IoHelpCircleOutline} w={6} h={6} color={"gray"} />
                }
                variant={"ghost"}
                colorScheme={"brand"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Text>{t("search.insights_description")}</Text>
                {user?.currentPlan !== "premium" && (
                  <Text>
                    {t("search.pricing_insights", {
                      price: 2,
                    })}
                  </Text>
                )}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormControl>

        <Button
          ml={-2}
          onClick={onToggle}
          variant={"ghost"}
          colorScheme={"brand"}
          leftIcon={<IoFilterOutline size={18} />}
        >
          {t("search.filters.btn")}
        </Button>
      </HStack>
      <SearchFilters isOpen={isOpen} onClose={onClose} />
    </Form>
  );
};

export default SearchBar;
