import { checkSessionToken } from "@/lib/cloudFunctions/session/CheckSessionToken";
import { setSessionToken } from "@/lib/cloudFunctions/session/SetSessionToken";
import { authIa, dbIa } from "@/lib/firebase";
import { User } from "@/lib/types";
import { onAuthStateChanged } from "firebase/auth";
import { Timestamp, doc, onSnapshot } from "firebase/firestore";
import { nanoid } from "nanoid";

import { createContext, useContext, useEffect, useRef, useState } from "react";

type UserSessionContextType = {
  user: User | null;
  isReady: boolean;
};

const UserSessionContext = createContext<UserSessionContextType>({
  user: null,
  isReady: false,
});

const UserSessionProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isReady, setIsReady] = useState(false);

  const unsubscribe = useRef<() => void>(() => {});
  useEffect(() => {
    onAuthStateChanged(authIa, async (user) => {
      if (user?.email) {
        //Make sure the user is logged in on one web session only
        const _lastLoginAt = new Date(
          user.metadata.lastSignInTime || (user.metadata.creationTime as string)
        );

        const currentTimestamp = new Date();
        const diff = currentTimestamp.getTime() - _lastLoginAt.getTime();

        //check if last login was more than 5 seconds ago
        if (diff > 5000) {
          const token = localStorage.getItem("sessionToken") || nanoid();
          console.log("token", token);
          const res = await checkSessionToken({ token: token });
          if (!res.data.success) {
            //User is logged in on another web session
            authIa.signOut();
            return;
          }
          localStorage.setItem("sessionToken", token);
        } else {
          //Set current session token
          const token = localStorage.getItem("sessionToken") || nanoid();
          localStorage.setItem("sessionToken", token);
          await setSessionToken({ token: token });
        }

        unsubscribe.current = onSnapshot(
          doc(dbIa, "users", user.email),
          (_doc) => {
            if (_doc.exists() === false) {
              setUser({
                id: user.email as string,
                ref: doc(dbIa, "users", user.email as string),
                createdAt: new Timestamp(Date.now() / 1000, 0),
                liked: [],
                firstName: "",
                lastName: "",
                status: "",
                domain: "",
                specialty: "",
                country: "",
                zip: "",
                cgv: false,
                newsletter: false,
                rpps: null,
                paperCoins: 500,
              });
            } else {
              setUser({
                ..._doc.data(),
                id: _doc.id,
                ref: _doc.ref,
              } as User);
              setIsReady(true);
            }
          }
        );
      } else {
        unsubscribe.current();
        setUser(null);
        setIsReady(true);
      }
    });
  }, []);
  return (
    <UserSessionContext.Provider value={{ user: user, isReady: isReady }}>
      {children}
    </UserSessionContext.Provider>
  );
};

const useUserSession = (): UserSessionContextType =>
  useContext(UserSessionContext);

export { useUserSession };
export default UserSessionProvider;
