import { addUserToCollection } from "@/lib/cloudFunctions/addUserToCollection";
import Container from "@/ui/Container";
import {
  Box,
  Button,
  CircularProgress,
  Heading,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

const JoinCollection = () => {
  const { code } = useParams<{
    code: string;
  }>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const toast = useToast();

  const navigate = useNavigate();
  useEffect(() => {
    if (!code) {
      navigate("/collections", { replace: true });
      return;
    }
    addUserToCollection({
      code,
    })
      .then((res) => {
        setLoading(false);
        toast({
          title: t("collections.joiningCollectionSuccess"),
          status: "success",
        });
        navigate(`/collections/${res.data.collection}`, { replace: true });
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [code, navigate, toast, t]);

  return (
    <Container hasSearch>
      <Box
        bgImage={"/backgrounds/logo-collections.svg"}
        bgSize={"cover"}
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"stretch"}
        justifyContent={"center"}
        gap={4}
        flex={1}
        color={"#fff"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
        position={"relative"}
        fontWeight={"bold"}
      >
        {loading && (
          <VStack>
            <Heading>{t("collections.joiningCollection")}</Heading>
            <CircularProgress isIndeterminate color="brand.500" />
          </VStack>
        )}
        {error && (
          <VStack>
            <Heading>{t("collections.joiningCollectionError")}</Heading>
            <Button as={Link} to="/collections">
              {t("collections.backToCollections")}
            </Button>
          </VStack>
        )}
      </Box>
    </Container>
  );
};

export default JoinCollection;
