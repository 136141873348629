import { useUserSession } from "@/hooks/useUserSession";
import semanticScholarApi, { PaperResponse } from "@/lib/SemanticScholarApi";

import SearchArticle from "@/ui/SearchArticle";

import {
  Box,
  CircularProgress,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  collection,
  getDocs,
  orderBy,
  query,
  getCountFromServer,
  limit,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { IoEyeOutline } from "react-icons/io5";

const Recap = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();
  const [stats, setStats] = useState({
    read: 0,
    searches: 0,
    summarized: 0,
    loading: true,
  });

  const [articles, setArticles] = useState<PaperResponse[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    (async () => {
      const readPromise = getCountFromServer(collection(user.ref, "history"));
      const summarizedPromise = getCountFromServer(
        collection(user.ref, "summaries")
      );
      const [read, summarized] = await Promise.all([
        readPromise,
        summarizedPromise,
      ]);

      setStats({
        read: read.data().count,
        searches: user.searchesCount || 0,
        summarized: summarized.data().count,
        loading: false,
      });
    })();
  }, [user]);

  useEffect(() => {
    if (!user) return;
    (async () => {
      setLoading(true);
      const { docs } = await getDocs(
        query(
          collection(user.ref, "history"),
          orderBy("readAt", "desc"),
          limit(5)
        )
      );

      const res = await semanticScholarApi.search.post(
        "/paper/batch",
        {
          ids: docs.map((doc) => {
            if (/^\d+$/.test(doc.id)) {
              return `CorpusId:${doc.id}`;
            }
            return doc.id;
          }),
        },
        {
          params: {
            fields:
              "title,authors,journal,externalIds,tldr,year,publicationDate,isOpenAccess,citationCount,corpusId,publicationTypes",
          },
        }
      );

      const articles = res.data.filter(
        (article: PaperResponse | null) => !!article
      );

      setLoading(false);
      setArticles(articles);
    })();
  }, [user]);
  return (
    <VStack alignItems={"stretch"} spacing={8}>
      <Box
        borderRadius={32}
        paddingX={[4, 4]}
        paddingY={[2, 4]}
        bgColor={"white"}
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
        gap={4}
        w={"100%"}
      >
        <Heading textAlign={"center"}>{t("profile.recap.stats")}</Heading>

        <Flex
          flexDir={"row"}
          wrap={"wrap"}
          gap={4}
          justifyContent={"space-evenly"}
        >
          <Box
            flex={1}
            p={4}
            borderRadius={16}
            display={"flex"}
            flexDir={"row"}
            alignItems={"center"}
            alignContent={"center"}
            gap={4}
            color={"white"}
            bgColor={"brand.500"}
          >
            <Box
              bgColor={"rgba(255,255,255,0.4)"}
              padding={6}
              borderRadius={16}
            >
              <IoEyeOutline size={32} />
            </Box>
            <VStack spacing={2} alignItems={"flex-start"}>
              <Heading size={"lg"}>{stats.read}</Heading>
              <Text size={"sm"}>{t("profile.recap.read")}</Text>
            </VStack>
          </Box>
          <Box
            flex={1}
            p={6}
            borderRadius={16}
            display={"flex"}
            flexDir={"row"}
            alignItems={"center"}
            alignContent={"center"}
            gap={4}
            color={"white"}
            bgColor={"#1A1E13"}
          >
            <Box
              bgColor={"rgba(255,255,255,0.4)"}
              padding={6}
              borderRadius={16}
            >
              <Image src="/images/search.svg" />
            </Box>
            <VStack spacing={2} alignItems={"flex-start"}>
              <Heading size={"lg"}>{stats.searches}</Heading>
              <Text size={"sm"}>{t("profile.recap.searches")}</Text>
            </VStack>
          </Box>
          <Box
            flex={1}
            p={6}
            borderRadius={16}
            display={"flex"}
            flexDir={"row"}
            alignItems={"center"}
            alignContent={"center"}
            gap={4}
            color={"white"}
            bgColor={"#5665EE"}
          >
            <Box
              bgColor={"rgba(255,255,255,0.4)"}
              padding={6}
              borderRadius={16}
            >
              <Image src="/images/ia.svg" />
            </Box>
            <VStack spacing={2} alignItems={"flex-start"}>
              <Heading size={"lg"}>{stats.summarized}</Heading>
              <Text size={"sm"}>{t("profile.recap.summarized")}</Text>
            </VStack>
          </Box>
        </Flex>
      </Box>
      <Box
        flex={1}
        borderRadius={32}
        paddingX={[4, 4, 12]}
        paddingY={[2, 4]}
        bgColor={"white"}
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
        gap={4}
      >
        <Heading textAlign={"center"}>{t("profile.recap.history")}</Heading>
        <CircularProgress
          isIndeterminate={loading}
          hidden={!loading}
          color="brand.500"
        />
        <VStack spacing={4}>
          {articles.map((article) => (
            <SearchArticle key={article.paperId} {...article} />
          ))}
        </VStack>
      </Box>
    </VStack>
  );
};

export default Recap;
