import { useUserSession } from "@/hooks/useUserSession";
import semanticScholarApi, { PaperResponse } from "@/lib/SemanticScholarApi";

import SearchArticle from "@/ui/SearchArticle";

import { Box, CircularProgress, Heading, VStack } from "@chakra-ui/react";

import { collection, getDocs, orderBy, query, limit } from "firebase/firestore";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const History = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();

  const [articles, setArticles] = useState<PaperResponse[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    (async () => {
      setLoading(true);
      const { docs } = await getDocs(
        query(
          collection(user.ref, "history"),
          orderBy("readAt", "desc"),
          limit(20)
        )
      );

      const res = await semanticScholarApi.search.post(
        "/paper/batch",
        {
          ids: docs.map((doc) => {
            if (/^\d+$/.test(doc.id)) {
              return `CorpusId:${doc.id}`;
            }
            return doc.id;
          }),
        },
        {
          params: {
            fields:
              "title,authors,journal,externalIds,tldr,year,publicationDate,isOpenAccess,citationCount,corpusId,publicationTypes",
          },
        }
      );

      const articles = res.data.filter(
        (article: PaperResponse | null) => !!article
      );

      setLoading(false);
      setArticles(articles);
    })();
  }, [user]);
  return (
    <Box
      flex={1}
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      borderRadius={32}
      paddingX={[4, 4, 12]}
      paddingY={[2, 4]}
      bgColor={"white"}
    >
      <Heading mb={4} textAlign={"center"}>
        {t("profile.menu.history")}
      </Heading>
      <CircularProgress
        isIndeterminate={loading}
        hidden={!loading}
        color="brand.500"
      />
      <VStack spacing={4}>
        {articles.map((article) => (
          <SearchArticle key={article.paperId} {...article} />
        ))}
      </VStack>
    </Box>
  );
};

export default History;
