import { functions } from "@/lib/firebase";
import { Collection } from "@/lib/types/Collection";
import { httpsCallable } from "firebase/functions";

type AddUserToCollectionRequest = {
  code: string;
};

type AddUserToCollectionResponse = {
  success: boolean;
  collection: Collection["id"];
};

const addUserToCollection = httpsCallable<
  AddUserToCollectionRequest,
  AddUserToCollectionResponse
>(functions, "addUserToCollection");

export { addUserToCollection };
