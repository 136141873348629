import CollectionMenu from "@/components/collections/CollectionMenu";
import CollectionSearch from "@/components/collections/CollectionsSearch";
import SearchBar from "@/components/SearchBar";
import { useCollections } from "@/hooks/useCollections";
import { dbIa } from "@/lib/firebase";
import semanticScholarApi, {
  SearchResponseItem,
} from "@/lib/SemanticScholarApi";
import Container from "@/ui/Container";
import SearchLoading from "@/ui/pending/SearchLoading";
import SearchArticle from "@/ui/SearchArticle";
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBack } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";

import ExportMenu from "@/components/collections/ExportMenu";
type CollectionSearchItem = {
  query: string;
  token: string;
};

const Collection = () => {
  const { collectionId } = useParams<{
    collectionId: string;
  }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { collections } = useCollections();
  const [searches, setSearches] = useState<Array<CollectionSearchItem>>([]);
  const [papers, setPapers] = useState<Array<SearchResponseItem>>([]);
  const [loaders, setLoaders] = useState({
    papers: true,
    searches: true,
  });
  const [errors, setErrors] = useState({
    papers: false,
    searches: false,
  });
  const collection = collections.find((c) => c.id === collectionId);

  useEffect(() => {
    if (!collection) {
      navigate("/collections", { replace: true });
      return () => {};
    }
    const paperIds = collection?.papers.map((e) => `CorpusId:${e}`) || [];
    //  const searchIds = collection?.searches || [];

    if (collection?.papers.length === 0) {
      setLoaders((loaders) => ({
        ...loaders,
        papers: false,
      }));
      setPapers([]);
    } else {
      semanticScholarApi.search
        .post(
          "/paper/batch",
          {
            ids: paperIds,
          },
          {
            params: {
              fields:
                "title,authors,journal,externalIds,tldr,publicationDate,year,citationCount,corpusId,publicationTypes",
            },
          }
        )
        .then((res) => {
          setPapers(res.data);
          setLoaders((loaders) => ({
            ...loaders,
            papers: false,
          }));
        })
        .catch((e) => {
          console.error(e);
          setErrors((errors) => ({
            ...errors,
            papers: true,
          }));
        });
    }

    if (collection?.searches.length === 0) {
      setLoaders((loaders) => ({
        ...loaders,
        searches: false,
      }));
      setSearches([]);
    } else {
      Promise.all(
        collection.searches.flatMap(async (search) => {
          const searchDoc = await getDoc(doc(dbIa, "ai_searches", search));
          if (!searchDoc.exists()) {
            return [];
          }
          return {
            query: searchDoc.data().query,
            token: searchDoc.id,
          };
        })
      ).then((_searches) => {
        setSearches(_searches as typeof searches);
        setLoaders((loaders) => ({
          ...loaders,
          searches: false,
        }));
      });
    }
  }, [collection, navigate]);

  return collection ? (
    <Container hasSearch>
      <Box
        bgImage={"/backgrounds/logo-collections.svg"}
        bgSize={"cover"}
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"stretch"}
        gap={4}
        flex={1}
        color={"#fff"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
        position={"relative"}
        fontWeight={"bold"}
      >
        <Button
          position={"absolute"}
          top={[4, 8]}
          left={[4, 8]}
          alignSelf={"flex-start"}
          as={Link}
          to={"/collections"}
        >
          <Icon as={MdArrowBack} w={6} h={6} mr={4} />
          {t("collections.back")}
        </Button>
        <HStack
          alignSelf={"center"}
          alignItems={"center"}
          gap={2}
          mt={[12, 12, 0]}
        >
          <Heading mb={1} textAlign={"center"}>
            {collection.isDefault ? t("collections.favs") : collection.name}
          </Heading>
          {!collection.isDefault && collection.isOwner && (
            <CollectionMenu collection={collection} iconColor="#fff" />
          )}
          <ExportMenu
            papers={papers}
            title={collection.isDefault ? "favorites" : collection.name}
          />
        </HStack>
        <Tabs variant={"unstyled"}>
          <TabList justifyContent={"center"}>
            <Tab
              borderBottomWidth={"2px"}
              borderBottomColor={"transparent"}
              borderStyle={"solid"}
              fontWeight={"bold"}
              fontSize={"larger"}
            >
              {t("collections.papers")} - {collection.papers.length}
            </Tab>
            <Tab
              borderBottomWidth={"2px"}
              borderBottomColor={"transparent"}
              borderStyle={"solid"}
              fontWeight={"bold"}
              fontSize={"larger"}
            >
              {t("collections.searches")} - {collection.searches.length}
            </Tab>
          </TabList>
          <TabIndicator
            mt={"-2px"}
            height="2px"
            bg="brand.100"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <VStack spacing={4}>
                {errors.papers && <Box>{t("collections.error")}</Box>}
                {loaders.papers ? (
                  <SearchLoading count={collection.papers.length} hideArchie />
                ) : papers.length === 0 ? (
                  <VStack spacing={4} textAlign={"center"}>
                    <Text>{t("collections.empty")}</Text>
                    <Text>{t("collections.tabs.papers.empty")}</Text>
                    <SearchBar />
                  </VStack>
                ) : null}
                {papers.map((paper) => (
                  <SearchArticle
                    key={paper.corpusId}
                    {...paper}
                    hideArchieAnswer
                  />
                ))}
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack spacing={4}>
                {errors.searches && <Box>{t("collections.error")}</Box>}
                {loaders.searches ? (
                  <SearchLoading
                    count={collection.searches.length}
                    hideArchie
                  />
                ) : searches.length === 0 ? (
                  <VStack spacing={4} textAlign={"center"}>
                    <Text>{t("collections.emptySearches")}</Text>
                    <Text>{t("collections.tabs.searches.empty")}</Text>
                    <SearchBar />
                  </VStack>
                ) : null}
                {searches.map((search) => (
                  <CollectionSearch search={search} />
                ))}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  ) : null;
};

export default Collection;
