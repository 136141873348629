import SideBar from "@/components/Sidebar";
import { Box } from "@chakra-ui/react";
import { Outlet, ScrollRestoration, useNavigate } from "react-router-dom";
import { useUserSession } from "./hooks/useUserSession";
import { useEffect } from "react";
import Footer from "./ui/Footer";
import { PaperCoinsDisclaimer } from "./hooks/usePaperCoinsDisclaimer";
import CompleteAuthBanner from "./components/CompleteAuthBanner";

const Layout = () => {
  const { user, isReady } = useUserSession();

  const navigate = useNavigate();

  useEffect(() => {
    if (isReady) {
      if (!user) {
        navigate("/login", {
          state: {
            from: window.location.pathname,
          },
        });
      }
    }
  }, [user, isReady, navigate]);

  return (
    <PaperCoinsDisclaimer>
      <Box
        width={"100%"}
        minH={"100vh"}
        background={"#E6F2F0"}
        display={"flex"}
        flexDir={"row"}
        alignItems={"stretch"}
        position={"relative"}
      >
        <SideBar />
        <Box
          display={"flex"}
          alignItems={"stretch"}
          justifyContent={"flex-start"}
          width={"100%"}
          minH={"100vh"}
          overflow={"hidden"}
          flexDirection={"column"}
        >
          <CompleteAuthBanner />
          <Outlet />
        </Box>
      </Box>
      <Footer />
      <ScrollRestoration />
    </PaperCoinsDisclaimer>
  );
};

export default Layout;
