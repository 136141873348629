import CollectionMenu from "@/components/collections/CollectionMenu";
import { useCollections } from "@/hooks/useCollections";
import { useUserSession } from "@/hooks/useUserSession";
import Container from "@/ui/Container";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Heading,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { refEqual } from "firebase/firestore";

import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom";

const Collections = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();

  const { collections, openCreateCollection } = useCollections();

  return (
    <Container hasSearch>
      <Box
        bgImage={"/backgrounds/logo-collections.svg"}
        bgSize={"cover"}
        color={"#FFF"}
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={4}
        flex={1}
        borderRadius={24}
        bgColor={"#F2F6F9"}
      >
        <Tabs variant={"unstyled"}>
          <TabList justifyContent={"center"}>
            <Tab
              borderBottomWidth={"2px"}
              borderBottomColor={"transparent"}
              borderStyle={"solid"}
              fontWeight={"bold"}
              fontSize={"larger"}
            >
              <Heading as={"h2"} fontSize={"xx-large"}>
                {t("collections.title")}
              </Heading>
            </Tab>
            <Tab
              borderBottomWidth={"2px"}
              borderBottomColor={"transparent"}
              borderStyle={"solid"}
              fontWeight={"bold"}
              fontSize={"larger"}
            >
              <Heading as={"h2"} fontSize={"xx-large"}>
                {t("collections.shared")}
              </Heading>
            </Tab>
          </TabList>
          <TabIndicator
            mt={"-2px"}
            height="2px"
            bg="brand.100"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <VStack mt={4} alignItems={"stretch"} minW={"50%"} gap={4}>
                <Card
                  bgColor={"white"}
                  borderRadius={12}
                  p={4}
                  orientation="horizontal"
                  minW={300}
                  cursor={"pointer"}
                  onClick={openCreateCollection}
                >
                  <CardBody padding={0}>
                    <HStack>
                      <Icon as={MdAdd} w={6} h={6} />
                      <Text fontWeight={"bold"}>
                        {t("collections.creation.create")}
                      </Text>
                    </HStack>
                  </CardBody>
                </Card>
                {collections.flatMap((collection) => {
                  if (!collection.isOwner) {
                    return [];
                  }
                  return (
                    <LinkBox
                      minW={300}
                      key={collection.id}
                      bgColor={"white"}
                      borderRadius={12}
                      p={4}
                      orientation="horizontal"
                      as={Card}
                    >
                      <CardBody padding={0}>
                        <HStack justifyContent={"space-between"}>
                          <HStack>
                            <LinkOverlay
                              as={Link}
                              to={`/collections/${collection.id}`}
                            >
                              <Text fontWeight={"bold"}>
                                {collection.isDefault
                                  ? t("collections.favs")
                                  : collection.name}
                              </Text>
                            </LinkOverlay>
                            {[...collection.viewers, ...collection.maintainers]
                              .length > 0 ? (
                              <Tag colorScheme={"green"}>
                                {t("collections.share.shared")}
                              </Tag>
                            ) : (
                              <Tag colorScheme={"blue"}>
                                {t("collections.share.private")}
                              </Tag>
                            )}
                          </HStack>
                          {!collection.isDefault && (
                            <CollectionMenu collection={collection} />
                          )}
                        </HStack>
                      </CardBody>
                      <CardFooter padding={0}>
                        <Text fontSize={"sm"} fontStyle={"italic"}>
                          {t("collections.count", {
                            papers: collection.papers.length,
                            searches: collection.searches.length,
                          })}
                        </Text>
                      </CardFooter>
                    </LinkBox>
                  );
                })}
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack mt={4} alignItems={"stretch"} minW={"50%"} gap={4}>
                {collections.flatMap((collection) => {
                  if (collection.isOwner) {
                    return [];
                  }
                  return (
                    <LinkBox
                      minW={300}
                      key={collection.id}
                      bgColor={"white"}
                      borderRadius={12}
                      p={4}
                      orientation="horizontal"
                      as={Card}
                    >
                      <CardBody padding={0}>
                        <HStack>
                          <LinkOverlay
                            as={Link}
                            to={`/collections/${collection.id}`}
                          >
                            <Text fontWeight={"bold"}>{collection.name}</Text>
                          </LinkOverlay>
                          {collection.viewers.find((e) =>
                            refEqual(e, user!.ref)
                          ) ? (
                            <Tag colorScheme={"blue"}>
                              {t("collections.share.viewer")}
                            </Tag>
                          ) : (
                            <Tag colorScheme={"green"}>
                              {t("collections.share.maintainer")}
                            </Tag>
                          )}
                        </HStack>
                      </CardBody>
                      <CardFooter padding={0}>
                        <Text fontSize={"sm"} fontStyle={"italic"}>
                          {t("collections.count", {
                            papers: collection.papers.length,
                            searches: collection.searches.length,
                          })}
                        </Text>
                      </CardFooter>
                    </LinkBox>
                  );
                })}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
};

export default Collections;
