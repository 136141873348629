import { useCollections } from "@/hooks/useCollections";
import { useUserSession } from "@/hooks/useUserSession";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { refEqual } from "firebase/firestore";

import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";

type AddElementToCollectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  elementId: string;
  elementType: "paper" | "search";
};

const AddElementToCollectionModal = (
  props: AddElementToCollectionModalProps
) => {
  const { isOpen, onClose, elementId, elementType } = props;
  const { user } = useUserSession();
  const { t } = useTranslation();
  const {
    collections,
    addElementToCollection,
    removeElementFromCollection,
    openCreateCollection,
  } = useCollections();
  const property = elementType === "paper" ? "papers" : "searches";

  const toggleElementInCollection = (collectionId: string) => {
    const collection = collections.find((c) => c.id === collectionId);
    if (!collection) return;
    if (collection[property].includes(elementId)) {
      removeElementFromCollection(elementType, collectionId, elementId);
    } else {
      addElementToCollection(elementType, collectionId, elementId);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bgColor={"#F2F6F9"}>
        <ModalHeader>{t("collections.addElement.title")}</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Card
              w={"100%"}
              p={4}
              orientation="horizontal"
              cursor={"pointer"}
              onClick={openCreateCollection}
            >
              <CardBody padding={0}>
                <HStack>
                  <Icon as={MdAdd} w={6} h={6} />
                  <Text fontWeight={"bold"}>{t("collections.create")}</Text>
                </HStack>
              </CardBody>
            </Card>
            {collections.flatMap((collection) => {
              if (
                !collection.isOwner &&
                !collection.maintainers.find((m) => refEqual(m, user!.ref))
              ) {
                return [];
              }
              return (
                <Card
                  w={"100%"}
                  p={4}
                  orientation="horizontal"
                  key={collection.id}
                  cursor={"pointer"}
                  onClick={toggleElementInCollection.bind(null, collection.id)}
                >
                  <CardBody padding={0}>
                    <HStack justifyContent={"space-between"}>
                      <HStack>
                        <Text fontWeight={"bold"}>
                          {collection.isDefault
                            ? t("collections.favs")
                            : collection.name}
                        </Text>
                        {!collection.isOwner ? (
                          collection.viewers.find((e) =>
                            refEqual(e, user!.ref)
                          ) ? (
                            <Tag colorScheme={"blue"}>
                              {t("collections.share.viewer")}
                            </Tag>
                          ) : (
                            <Tag colorScheme={"green"}>
                              {t("collections.share.maintainer")}
                            </Tag>
                          )
                        ) : [...collection.viewers, ...collection.maintainers]
                            .length > 0 ? (
                          <Tag colorScheme={"green"}>
                            {t("collections.share.shared")}
                          </Tag>
                        ) : (
                          <Tag colorScheme={"blue"}>
                            {t("collections.share.private")}
                          </Tag>
                        )}
                      </HStack>
                      <Checkbox
                        colorScheme="brand"
                        onChange={toggleElementInCollection.bind(
                          null,
                          collection.id
                        )}
                        isChecked={collection[property].includes(elementId)}
                      />
                    </HStack>
                  </CardBody>
                  <CardFooter padding={0}>
                    <Text fontSize={"sm"} fontStyle={"italic"}>
                      {t("collections.count", {
                        papers: collection.papers.length,
                        searches: collection.searches.length,
                      })}
                    </Text>
                  </CardFooter>
                </Card>
              );
            })}
          </VStack>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button colorScheme="brand" onClick={onClose}>
            {t("collections.addElement.add")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddElementToCollectionModal;
