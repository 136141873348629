import useArchieData from "@/hooks/useArchieData";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IoFilterOutline, IoHelpCircleOutline } from "react-icons/io5";

import SearchFilters from "./SearchFilters";

const SearchActions = () => {
  const { displayed, setArchieData } = useArchieData();
  const { onToggle, isOpen, onClose } = useDisclosure();

  const { t } = useTranslation();
  return (
    <>
      <HStack justifyContent={"flex-start"} flexWrap={"wrap"}>
        <FormControl w={"auto"} display={"flex"} alignItems={"center"}>
          <Switch
            onChange={(e) => {
              if (e.target.checked) {
                setArchieData({
                  displayed: {
                    ...displayed,
                    aiSearch: true,
                  },
                });
              } else {
                setArchieData({
                  displayed: {
                    aiSearch: false,
                    insights: false,
                    summary: false,
                  },
                });
              }
            }}
            isChecked={displayed.aiSearch}
            mr={2}
            id="ai-search"
            name="ai-search"
            colorScheme="brand"
            value={"true"}
          />
          <FormLabel htmlFor="ai-search" mt={2}>
            {t("search.ai_search")}
          </FormLabel>
          <Popover>
            <PopoverTrigger>
              <IconButton
                ml={-4}
                p={0}
                aria-label="info"
                icon={
                  <Icon as={IoHelpCircleOutline} w={6} h={6} color={"gray"} />
                }
                variant={"ghost"}
                colorScheme={"brand"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Text>{t("search.ai_search_description")}</Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormControl>
        <FormControl w={"auto"} display={"flex"} alignItems={"center"}>
          <Switch
            onChange={(e) => {
              setArchieData({
                displayed: {
                  ...displayed,
                  aiSearch: e.target.checked ? true : displayed.aiSearch,
                  insights: e.target.checked,
                },
              });
            }}
            isChecked={displayed.insights}
            mr={2}
            id="insights"
            name="insights"
            colorScheme="brand"
            value={"true"}
          />
          <FormLabel htmlFor="insights" mt={2}>
            Archie
          </FormLabel>

          <Popover>
            <PopoverTrigger>
              <IconButton
                ml={-4}
                p={0}
                aria-label="info"
                icon={
                  <Icon as={IoHelpCircleOutline} w={6} h={6} color={"gray"} />
                }
                variant={"ghost"}
                colorScheme={"brand"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Text>{t("search.insights_description")}</Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormControl>

        <Button
          onClick={onToggle}
          variant={"ghost"}
          colorScheme={"brand"}
          leftIcon={<IoFilterOutline size={18} />}
        >
          {t("search.filters.btn")}
        </Button>
      </HStack>
      <SearchFilters isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default SearchActions;
