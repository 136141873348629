import axios from "axios";
import { AiSearchResponse } from "./archie";

interface SearchResponseItem {
  title: string;
  tldr: {
    text: string;
  } | null;
  paperId: string;
  journal: {
    name: string;
  };
  authors: {
    name: string;
  }[];
  externalIds: {
    DOI: string;
  };
  publicationDate: string;
  year: number;
  citationCount: number;
  corpusId: number;
  citationStyles?: {
    bibtex: string;
  };
  venue: string;
  isOpenAccess: boolean;
  openAccessPdf: {
    url: string;
  } | null;
  url: string;
  abstract: string | null;
  publicationVenue?: {
    id: string;
    name: string;
    type: string;
    alternate_names: string[];
    issn: string;
    url?: string;
  };
  publicationTypes?: string[];
}

interface ClassicSearchResponse {
  data: SearchResponseItem[];
  total: number;
  isAiSearch: false;
}

interface AugmentedSearchResponse extends AiSearchResponse {
  isAiSearch: true;
  total: undefined;
}

interface PaperResponse {
  paperId: string;
  title: string;
  tldr: {
    text: string;
  } | null;
  journal: {
    name: string;
  };
  authors: {
    name: string;
  }[];
  externalIds: {
    DOI: string;
  };
  abstract: string | null;
  publicationDate: string;
  year: number;
  corpusId: string;
  citationCount: number;
  summary?: Summary;
  isOpenAccess: boolean;
  openAccessPdf: {
    url: string;
  } | null;
  url: string;
  publicationTypes?: string[];
}

type Summary = {
  title: string;
  value: {
    title: string;
    text: string;
  }[];
}[];

const apiKey = "vucMnrG9hO1Z7n0JZ5QKQ3Kj1QbkgEbMUbKhrgm1";

const search = axios.create({
  baseURL: "https://api.semanticscholar.org/graph/v1",
  headers: {
    "Content-Type": "application/json",
    "x-api-key": apiKey,
  },
});

const recommandations = axios.create({
  baseURL: "https://api.semanticscholar.org/recommendations/v1",
  headers: {
    "Content-Type": "application/json",
    "x-api-key": apiKey,
  },
});

const semanticScholarApi = {
  search,
  recommandations,
};

export type {
  PaperResponse,
  SearchResponseItem,
  Summary,
  ClassicSearchResponse,
  AugmentedSearchResponse,
};
export default semanticScholarApi;
