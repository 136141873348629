import { useUserSession } from "@/hooks/useUserSession";
import AuthContainer from "@/ui/Auth/AuthContainer";
import { Heading } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Register = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    console.log(state);
    if (user) {
      if (state && state.from) {
        navigate(state.from, { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [user, navigate, state]);
  return (
    <>
      {" "}
      <AuthContainer>
        <Heading mb={8} color={"#fff"}>
          {t("auth.register.subtitle.base")}
        </Heading>

        <Outlet />
      </AuthContainer>
    </>
  );
};

export default Register;
