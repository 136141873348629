import SearchActions from "@/components/search/SearchActions";
import useSearchSubmit from "@/hooks/useSearchSubmit";
import Container from "@/ui/Container";
import { Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";

import { Form, Link } from "react-router-dom";

const SearchEngine = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmit = useSearchSubmit(formRef);
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  return (
    <Container hasSearch>
      <Box
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={4}
        flex={1}
        borderRadius={24}
        bgColor={"#F2F6F9"}
      >
        <Box
          flexGrow={1}
          display={"flex"}
          flexDir={"column"}
          alignItems={"stretch"}
          gap={8}
          pt={"15%"}
        >
          <Image
            src="/images/logo-full.svg"
            alt="Paperdoc"
            width={300}
            mx={"auto"}
          />
          <Text textAlign={"center"} fontSize={18} fontStyle={"italic"}>
            {t("search.subtitle")}
          </Text>

          <Form
            method="get"
            action="/search"
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display={"flex"} justifyContent={"center"}>
              <InputGroup
                bgColor={"#fff"}
                borderRadius={25}
                maxW={"800px"}
                _focusWithin={{
                  outline: "2px solid #00BCB0",
                }}
              >
                <Input
                  as={Textarea}
                  minH={10}
                  maxH={100}
                  type="text"
                  placeholder={t("search.placeholder")}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                  name="q"
                  id="q"
                  border={"none"}
                  borderRadius={25}
                  required
                  w={"100%"}
                  inputMode="search"
                  _focusVisible={{
                    outline: "none",
                  }}
                  onKeyDownCapture={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      handleSubmit();
                    }
                  }}
                />
                <InputRightAddon
                  bgColor={"#fff"}
                  border={"none"}
                  borderRadius={50}
                  h={10}
                  p={1}
                  pl={0}
                >
                  {search.length > 0 && (
                    <IconButton
                      aria-label="clear"
                      icon={<Icon as={IoClose} w={6} h={6} color={"gray"} />}
                      variant={"ghost"}
                      onClick={() => setSearch("")}
                    />
                  )}
                  <Button
                    h={8}
                    borderRadius={50}
                    variant={"primary"}
                    type="submit"
                  >
                    <Box hideBelow={"md"}>{t("search.submit")}</Box>
                    <Icon as={Search2Icon} w={4} h={4} hideFrom={"md"} />
                  </Button>
                </InputRightAddon>
              </InputGroup>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <SearchActions />
            </Box>
          </Form>

          <Text
            textAlign={"center"}
            fontSize={18}
            fontStyle={"italic"}
            sx={{
              a: {
                color: "brand.500",
                textDecoration: "underline",
              },
              textWrap: "balance",
            }}
          >
            <Trans i18nKey="search.help" t={t}>
              Parcourez une base de données de +220 millions d'articles couvrant
              tous les domaines scientifiques. Pour en savoir plus, allez sur la
              rubrique
              <Link to={"/help"}>'Comment ça marche?'</Link>.
            </Trans>
          </Text>
        </Box>
      </Box>
    </Container>
  );
};

export default SearchEngine;
