import { functions } from "@/lib/firebase";
import { httpsCallable } from "firebase/functions";

type CheckSessionTokenRequest = {
  token: string;
};

type CheckSessionTokenResponse = {
  success: boolean;
};

const checkSessionToken = httpsCallable<
  CheckSessionTokenRequest,
  CheckSessionTokenResponse
>(functions, "checkWebSessionToken");

export { checkSessionToken };
